.history-list-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: center;
  max-height: 82vh;
  overflow-y: scroll;
}

div#history-list-container > * {
  padding: 10px;
}

div#history-card  {
  border-radius: 6px;
  background: rgba( 255, 255, 255, 0.25 ) !important;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 37%);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgb(255 255 255 / 0%);
  color: white !important;
  height: 550px !important;
}

.img-list {
  height: 245px;
}

.img-out {
  height: 245px;
  width: 245px;
  opacity: 0.6;
}

p {
  max-height: 150px;
  overflow-y: scroll;
}

.p-card-subtitle {
  color: white !important;
}

.span-obs {
  word-wrap: break-word;
}  

.p-card-header img {
  border-radius: 10px 10px 0px 0px;
}

.footer-detalhes {
  transition: 0.3s ease all;
  font-size: 20px;
}
.footer-detalhes:hover {
  cursor: pointer;
}
.footer-detalhes > * {
  margin-left: 5px;
}

.btn-mais-detalhes  {
  font-size: 15px;
  border: purple !important;
  background-color: rgb(180, 0, 180) !important;
}

.btn-mais-detalhes:hover {
  background-color: rgb(63, 3, 63) !important;
}

.flex {
  display: flex;
}

.flex > * {
  margin-left: 5px;
  margin-right: 5px;
}

.flex-button {
  display: flex;
  font-size: 17px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}

.btn-excluir {
  width: 153.75px;
}

.h245 {
  height: 245px !important;
}

.p-card-header {
  height: 245px !important;
}