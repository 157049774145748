.App {
  text-align: center;
  height: 100%;
}

div#alertlist-container {
  position: absolute;
  height: 750px !important; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;

  background: rgba(172, 156, 179, 0.212);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur( 2px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba(82, 1, 99, 0.18);
}

.alertlist-body {
  height: 670px;
  max-height: 670px;
  padding-top: 15px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.alertlist-header {
  color: white;
  height: 70px;
  border-bottom: 1px solid #ffffff1a;
  font-size: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
}

div.alert-list-card {
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
}

.p-card-title {
  color: white !important;
}

.btn-open {
  border-color: purple !important;
  background-color: purple !important;
}

.btn-open:hover {
  border-color: rgb(61, 2, 61) !important;
  background-color: rgb(61, 2, 61) !important;
}

.principal-card {
  background-color: rgba(255, 230, 0, 0.541) !important;
}

.p-card {
  transition: 0.3s ease all;
}