

.side-bar {
  height: 100%;
  width: 180px;
  color: white;
  background: rgb(255 255 255 / 10%);
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 26%);
  backdrop-filter: blur( 2px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgb(255 255 255 / 8%);
}

.p-menu {
  padding: 0.25rem 0;
  background: #ffffff00 !important;
  color: unset !important;
  border: unset !important;
  border-radius: unset !important;
  width: 100% !important;
  max-width: 100% !important;
}

.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1.25rem;
  background: #ffffff00 !important;
  color: #f7d8f7 !important;
  font-weight: 700;
  border-bottom: 1px solid #ffffff1c;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  color: #ffffff !important;
  transition: 0.3s ease all !important;
}

.p-menuitem-link:hover {
  transition: 0.3s ease all !important;
  background-color: rgb(89, 4, 100) !important;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 26%);
}

.btn-signout {
  font-size: 20px !important;
  width: 100px;
  margin-bottom: 20px;
  color: rgb(89, 4, 100) !important;
  background-color: white !important;
}

.signout {
  position: absolute;
  bottom: 0;
}