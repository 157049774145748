.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  height: 765px;
  width: 550px;
  box-shadow: 0px 14px 21px -8px rgb(0 0 0);
  overflow-x: hidden !important;
}

.login-form {
  display: grid;
  grid-template-rows: 8% auto;
  grid-template-columns: 100%;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  background-color: #f3f3f3;
}  

.logo {
  height: 200px;
}
.input-login {
  border: 0;
  border-bottom: 1px solid #000000;
  margin-bottom: 40px;
  height: 50px;
  width: 100%;
  background: transparent;
  border-radius: 5px;
  outline: none;
  padding-left: 40px;
}

.form-login-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


.input-icon {
  width: 100%;
  display: flex;
}

.icon-input {
  position: absolute;
  margin-top: 16px;
  margin-left: 16px;
}

.acess-icon {
  margin-right: 10px;
}

button#accessButton.btn-primary {
  border: 1px solid #6f0862 !important;
  background-color: #6f0862 !important;
  height: 60px !important;
  margin-top: 30px;
  border-radius: 10px !important;
  font-size: 26px !important;
  font-weight: 300 !important;
}

button#accessButton.btn-primary:hover {
  border: 1px solid #990c86 !important;
  background-color: #990c86 !important;
}