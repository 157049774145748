.App {
  text-align: center;
  height: 100%;
} 

.auditoria-date-header {
  display: grid;
  color: #ffffffd5;
  grid-template-rows: 100%;
  font-family: 'Ubuntu', sans-serif;
  grid-template-columns: auto 22.5% auto;
  margin-top: 20px;
  
}

.auditoria-line {
  min-width: 100%;
  width: 100%;
  border-bottom: 1px solid #ffffff42;
}

.auditoria-date-header-title {
  position: relative;
  top: 8px;
}

.auditoria-item {
  border-bottom: 1px solid #7b4c7d;
  padding-bottom: 10px;
}

.auditoria-chat-ballon-container {
  padding: 10px;
}

.auditoria-chat-ballon {
  display: flex;
  color: white;
  background: rgba(255, 255, 255, 0.19);
  border-radius: 16px;
  backdrop-filter: blur(6.4px);
  -webkit-backdrop-filter: blur(6.4px);
  border: 1px solid rgba(255, 255, 255, 0.08);
  min-height: 200px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.219);
  border-radius: 15px;
}

.auditoria-chat-ballon > * {
  height: 100%;
}

.user-icon {
  display: flex;
  color: #b1b1b1;
  justify-content: center;
  align-items: center;
  font-size: 45px;
}

.icon-bg {
  width: 70px;
  height: 70px;
  background: #3a0833;
  border-radius: 50%;
  box-shadow: 2px 3px 5px 0px rgb(0 0 0 / 75%);
}

.ballon-text {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
}

.mail {
  font-size: 20px;
  height: 25px;
}

.body {
  width: 100%;
  padding-top: 10px;
  height: 175px;
  display: flex;
}

.grid-chat {
  max-height: 82vh !important;
  height: 82vh;
  display: grid;
  grid-template-rows: auto 20%;
}

.chat-message {
  width: 100%;
  background: rgba(255, 255, 255, 0.19);
  backdrop-filter: blur(6.4px);
  border: 1px solid rgba(255, 255, 255, 0.08);
  height: 100%;
  box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 22%);
  border-radius: 15px;

  display: grid;
  grid-template-columns: 12% auto 10%;
}

.p-10 {
  padding: 10px;
}

.icon-container-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 35px;
}

.text-container-chat {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
}

.text-container-chat > textarea {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: transparent;
  outline: unset;
  border-color: #ffffff8a;
  color: white;
  font-family: 'Ubuntu', sans-serif;
}

.paper-plane {
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  background-color: #3a0833;
  justify-content: center;
  border-radius: 50%;
}

.icon-chat {
  transition: 0.3s ease all;
  outline: none !important;
  cursor: pointer;
}

.icon-chat:hover {  
  color: grey;
}

.icon-chat:focus {  
  color: rgb(48, 1, 34);
}

textarea::placeholder {
  color: white; 
}

.scroll-y {
  max-height: 100%;
  overflow-y: scroll;
}

.screen-grid {
  display: grid;
  grid-template-rows: 11% auto;
} 

.detail-panel {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  color: white;
  height: 82vh;
  font-family: 'Ubuntu', sans-serif;
  box-shadow: inset 0 0 10px #ffffff26;
}

.galeria-detalhes {
  width: 535px;
}

.obs-area > textarea {
  resize: none;
  height: 90%; 
  width: 100%;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.532);
  border-radius: 15px;
  outline: unset;
  padding: 10px;
  color: white;
  max-height: 90%;
  overflow-y: scroll;
}

.obs-area > label {
  margin-bottom: 10px;
}

.obs-area {
  height: 22vh;
} 