.nav-search-bar {
  background: #ffffff1f;
  border-radius: 20px;
  display: flex;
  padding: 8px 15px 8px 15px;
  justify-content: space-around;
  border: 1px solid #ffffff21;
}

input.search-bar-field::placeholder {  
  color: white !important;
}

span.search-bar-calendar input.p-inputtext::placeholder {  
  color: white !important;
}

nav#search-bar {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchbar-dropdown {
  height: 35px;
} 

#search-bar-container > * {
  display: flex;
  flex-direction: row;
  width: 22% !important;
  height: 35px;
}

#search-bar-container > div > span.p-calendar {
  padding-top: 6px;
}

#search-bar-container > div > * {
  color: white;
  background-color: transparent;
  border: unset !important;
  border-bottom: 1px solid white !important;
  text-align: start !important;
  border-radius: 5px !important;
  width: 90%;
  padding-left: 20px;
}


#search-bar-container > div > svg {
  position: relative;
  top: 12px;
  left: 20px;
  width: 20px !important;
  border: none !important;
  border-bottom: none !important;
}

.p-inputtext {
  background: #46464600 !important;
  border: 1px solid #ced4da00 !important;
  color: white !important;
}
.search-bar-field {
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: unset;
  color: #4f505d;
}


div.searchbar-dropdown span.p-dropdown-label {
  background: transparent;
  border: 0 none;
  position: relative;
  bottom: 7px;
}

div.searchbar-dropdown .p-dropdown-item {
  transition: 0.3s ease all !important; 
  color: white !important;
}

.p-dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.425) !important;
  color: rgb(77, 10, 80) !important;
}

.p-dropdown-items {
  transition: 0.3s ease all !important; 
}

.p-dropdown-panel {
  color: black !important;
}

.p-dropdown .p-dropdown-trigger {
  color: white !important;
}

li.p-dropdown-item {
  color: black !important;
}

.flex {
  display: flex;
}

.p-dropdown-item.p-highlight {
  background-color: transparent !important;
}

.p-card .p-card-title {
  font-family: 'Ubuntu', sans-serif;
  letter-spacing: 3px;
  font-size: 1rem !important;
  font-weight: 300 !important;
  white-space: nowrap !important;
}

.reload {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 20px;
}