.App {
  text-align: center;
  height: 100%;
  width: 95vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

div#loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(2.5);
}

div#loader > div {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2c0b3680;
}

.div#toastrc {
  position: fixed;
  left: 50%;
  top: 50%;
}

.pt-10 {
  padding: 20px;
}

.p-tabview .p-tabview-panels {
  background: #ffffff00 !important;
  padding: 1.25rem;
  border: 0 none;
  color: #495057;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-tabview .p-tabview-nav {
  background: #ffffff00 !important;
  border-bottom-color: #990e6e !important;
  border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff00 !important;
  border-color: #ffffff !important;
  color: #ff0fff !important;
  letter-spacing: 4px;
  font-family: 'Ubuntu', sans-serif;
}

.p-tabview .p-tabview-panels {
  padding: 0 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: #ffffff00 !important;
  color: #ffffff !important; 
  letter-spacing: 4px;
}

.full-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
} 

.form-group {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
}

.form-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
}

.form-data > input {
  font-family: 'Ubuntu', sans-serif;
  width: 100%;
  background: transparent;
  outline: none;
  color: white;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.402);
  font-size: 18px !important;
  margin-top: 8px;
}

.form-data > label {
  font-size: 20px;
}


.form-data > * {
  font-family: 'Ubuntu', sans-serif !important;
  margin-bottom: 4px;
}

.flex-column {
  flex-direction: column;
}