.App {
  text-align: center;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}  

.card-alert-container {
  display: grid;
  grid-template-rows: 10% 90%;
  grid-template-columns: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  border-radius: 12px;
  height: 675px;
  width: 1200px;
  overflow-x: hidden !important;
  background-color: white;
}

.card-alert-container-header {
  height: 100%;

  background: linear-gradient(-225deg, #A445B2 0%, #9a0c50 52%, #7a0232 100%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-alert-container-body {
  display: grid;
  grid-template-columns: 50% 50%;
}

div#alert-card {
  height: 100% !important;
  width: 100% !important;
}

.img-alert {
  max-width: 550px;
}

.img-alert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.obs {
  padding: 5px;
  height: 285px;
  border: 1px solid black;
  border-radius: 10px;
  outline: unset;
  width: 90%;
  font-size: 15px !important;
  resize: none !important;
}

.action-button-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
}

.action-button-container > button {
  height: 40px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button#ok {
  background-color: green;
  border-color: green;
}

button#ok:hover {
  background-color: rgb(2, 70, 2);
  border-color: rgb(2, 70, 2);
}

.footer {
  width: 100%;
  height: 100%;
  display: grid;
  flex-direction: column;
  grid-template-rows: 80% auto;
  padding-bottom: 30px;
}

.obs-container {
  display: flex;
  flex-direction: column;
  font-size: 1.5em;
  align-items: center;
  justify-content: center;
}

.p-galleria-thumbnail-item-content > * {
  height: 50px;
}

.imgTemplate {
  max-height: 370px !important;
}

.p-galleria {
  box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.75)
}


div#alert-container {
  transform: scale(0.85);
}

div#maildropdown > .p-dropdown-trigger {
  color: black !important;
}


.btn-encaminhar {
  margin-top: 20px;
  border-color: purple !important;
  background-color: purple !important;
}

.btn-encaminhar:hover {
  border-color: rgb(61, 2, 61) !important;
  background-color: rgb(61, 2, 61) !important;
}

#maildropdown.p-dropdown .p-dropdown-label.p-placeholder {
  color: black !important;
}

#maildropdown span.p-dropdown-label.p-inputtext {
  color: rgb(66, 66, 66) !important;
}