body {
  margin: 0;
  height: 100vh;
  background-color: #310e68;
  background-image: linear-gradient(316deg, #310e68 0%, #5f0f40 74%);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-group {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}



.full-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 12px; /* Largura da barra de rolagem */
}

/* Estilizando o polegar da barra de rolagem */
::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

/* Estilizando a barra de rolagem quando o mouse passa sobre ela */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 0, 0);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.title-list {
  color: #ffffffb8;
  border-bottom: 1px solid #ffffff42;
  letter-spacing: 10px;
  font-weight: 100;
}

.p15 {
  padding: 15px;
}